import { BLOCKS } from '@contentful/rich-text-types'
import { Text } from "@contentful/rich-text-types/dist/types/types";
import { graphql, Link, PageProps } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, {ReactNode} from 'react'

import Hero from '@/components/hero'
import Layout from '@/components/layout'
import Seo from "@/components/seo";
import Tags from '@/components/tags'
import * as styles from '@/templates/blog-post.module.scss'

const BlogPostTemplate: React.FC<PageProps<GatsbyTypes.BlogPostByIdAndPrevNextQuery>> = ({ data }) => {
  const post = data.contentfulBlog
  const previous = data.previous
  const next = data.next

  const codeStyle = {
    backgroundColor: `var(--black-fade-5)`,
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: { data: { target: { gatsbyImageData: GatsbyTypes.Scalars['JSON'], title: string } } }) => {
        const { gatsbyImageData, title } = node.data.target
        if (!gatsbyImageData) {
          return null
        }

        return <GatsbyImage image={gatsbyImageData}  alt={title}/>
      },
      [BLOCKS.PARAGRAPH]: (node: { content: Array<Text>}, children: ReactNode) => {
        if (
          node.content.length === 1 &&
          node.content[0].marks.find((x) => x.type === "code")
        ) {
          return <div style={codeStyle}>{children}</div>;
        }
        return <p>{children}</p>;
      },
    },
  }

  const postTitle: string = post?.title ?? ''
  const shortDescription: string = post?.shortDescription ?? ''
  const postImageUrl: string = post?.image?.resize?.src ?? ''
  const postImage = post?.image

    return (
      <Layout title={postTitle}>
        <Seo
          title={postTitle}
          description={shortDescription}
          image={postImageUrl}
          meta={[]}
          />
        <Hero
          image={postImage}
          title={postTitle}
          descriptions={[shortDescription]}
        />
        <div className={styles.container}>
          <div className={styles.article}>
            {/* @ts-ignore */}
            <div>{renderRichText(post.body, options)}</div>
            {/* @ts-ignore */}
            <Tags tags={post.tags ?? []} />
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/blog/${previous.id}`} rel="prev">← {previous.title}</Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/blog/${next.id}`} rel="next">{next.title} →</Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostByIdAndPrevNext(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    contentfulBlog(id: { eq: $id }) {
      id
      title
      shortDescription
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            gatsbyImageData
          }
        }
      }
      tags
      image {
        title
        gatsbyImageData
        resize {
          src
        }
      }
      publishedAt
    }
    previous: contentfulBlog(id: { eq: $previousPostId }) {
      id
      title
    }
    next: contentfulBlog(id: { eq: $nextPostId }) {
      id
      title
    }
  }
`
